._1RdTA {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

._1NGEq {
  transition-timing-function: cubic-bezier(0, 0.99, 1, 1.01);
  transform-style: preserve-3d;
}

._2lNIy {
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

._2J0Sd {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

._2ZrgR {
  transition: 1s all;
  transition-timing-function: cubic-bezier(0, 0.99, 1, 1.01);
}

._3PNLm {
  display: flex;
  justify-content: center;
  overflow: hidden;
  /* border: 2px solid blue; */
}

._bc6pG {
  transition-timing-function: cubic-bezier(0, 0.99, 1, 1.01);
  transform-style: preserve-3d;
}

._1kvjY {
  position: absolute;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}
